import '@/css/app.css';
import Alpine from "alpinejs";
import Toolkit from '@alpine-collective/toolkit'


// AlpineJS Plugins
import persist from "@alpinejs/persist"; // @see https://alpinejs.dev/plugins/persist
import collapse from "@alpinejs/collapse"; // @see https://alpinejs.dev/plugins/collapse
import intersect from "@alpinejs/intersect"; // @see https://alpinejs.dev/plugins/intersect

// Third Party Libraries
/*
    Scrollbar Library
    @see https://github.com/Grsmto/simplebar
*/
import SimpleBar from "simplebar";

// Pages Scripts
import * as pages from "./pages";

// Global Store
import store from "./store";
// Breakpoints Store
import breakpoints from "./utils/breakpoints";
import $http, {httpStart} from "./utils/http.js";

// Hex directives
import hexErrors from './directives/hexErrors.js';
import formMessages from './directives/formMessages.js';



window.SimpleBar = SimpleBar;

window.Alpine = Alpine;
window.pages = pages;
window.$http = $http;


Alpine.plugin(persist);
Alpine.plugin(collapse);
Alpine.plugin(intersect);

Alpine.plugin(httpStart);
Alpine.plugin(hexErrors);
Alpine.plugin(formMessages);

Alpine.store("breakpoints", breakpoints);
Alpine.store("global", store);

Alpine.plugin(Toolkit)
Alpine.start();

